import React, { useState, useEffect } from "react";
import config from "./config.js";
import axios from "axios";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { handleLogout } from "./Logout";
import { useTranslation } from "react-i18next";
import "./styles.css";

const Floors = ({ subscribedPOId }) => {
  const [floor, setFloor] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    console.log("Fetching floors...");
    fetchFloor();
    console.log("Selected PO:", subscribedPOId);
  }, [subscribedPOId]);

  const fetchFloor = async (e) => {
    try {
      const accessToken = localStorage.getItem("access_token");

      const response = await axios.get(
        config.url+`/production_orders/${subscribedPOId}/floor`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        const data = await response.data;
        console.log(data);
        setFloor(response.data);
      }
    } catch (error) {
      if (error.response && error.response.status > 400) {
        // Unauthorized
        console.log("Unauthorized");
        handleLogout();
      }
      console.error("Error saving tag:", error);
    }
  };

  return (
    <Box>
      <Typography variant="h4" className="custom-h4">
        {t("floor")}
      </Typography>
      <Typography variant="h5" component="h1" className="custom-h4">
        {subscribedPOId
          ? t("you_are_currently_working_on") + ": " + subscribedPOId
          : t("there_is_no_floor_assigned_to_this_po")}
      </Typography>

      <Typography variant="h5" component="h2" className="custom-h4">
        {floor && floor.id
          ? t("tag_floor_with_id") + ": " + floor.id
          : t("there_is_no_floor_assigned_to_this_po")}
      </Typography>
    </Box>
  );
};

export default Floors;
