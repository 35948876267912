//This file contains the LogisticsContent component which is the content of the logistics page. It contains the content items for the logistics page.
//This file contains the OperatorContent component which is the content of the operator page. It contains the content items for the operator page.
import React, { useState } from "react";
import config from "./config.js";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import Logout from "./Logout.js";
import Tags from "./Tags.js";
import Stations from "./Stations.js";
import Carts from "./Carts.js";
import ProductionOrders from "./ProductionOrders.js";
import Users from "./Users.js";
import AntennaManager from "./AntennaManager.js";
import Charts from "./Charts.js";
import AboutUs from "./AboutUs.js";
import Incidents from "./Incidents.js";
import Documentation from "./Documentation.js";
import { useTranslation } from "react-i18next";
import "./styles.css";
import UserSettings from "./Settings.js";
import VerticalWarehouse from "./VerticalWarehouse.js";

const LogisticsContent = ({
  selectedSection,
  subscribedPOId,
  setSubscribedPOId,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}>
      <br />
      <br />
      <br />

      {selectedSection === "Production Orders" && (
        <ProductionOrders
          setSubscribedPOId={setSubscribedPOId}
          subscribedPOId={subscribedPOId}
        />
      )}

      {selectedSection === "VerticalWarehouse" && <VerticalWarehouse />}
      {selectedSection === "About Us" && <AboutUs />}

      {selectedSection === "settings" && <UserSettings />}
    </Box>
  );
};

export default LogisticsContent;
