import React from "react";
import axios from "axios";
import { Button, Box } from "@mui/material";
import config from "./config";

const Charts = () => {
  // Función para descargar el archivo Excel del historial de suscripciones de órdenes de producción
  const downloadProductionOrderHistory = async () => {
    try {
      const response = await axios.get(
        config.url + "/operator-production-order-subscription-history",
        {
          responseType: "blob", // Para manejar la respuesta como un archivo
        }
      );

      // Crear un enlace para descargar el archivo
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "operator_production_order_subscription_history.xlsx"
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error(
        "Error downloading production order subscription history",
        error
      );
    }
  };

  // Función para descargar el archivo Excel del historial de suscripciones de estaciones
  const downloadStationSubscriptionHistory = async () => {
    try {
      const response = await axios.get(
        config.url + "/operator-station-subscription-history",
        {
          responseType: "blob", // Para manejar la respuesta como un archivo
        }
      );

      // Crear un enlace para descargar el archivo
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "operator_station_subscription_history.xlsx"
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading station subscription history", error);
    }
  };

  return (
    <Box>
      {/* Botón para descargar el archivo Excel de historial de órdenes de producción */}
      <Button
        variant="contained"
        color="secondary"
        onClick={downloadProductionOrderHistory}
        style={{ margin: "20px" }}
      >
        Bajar excel de historial de suscripciones de operadores a órdenes de
        producción
      </Button>

      {/* Botón para descargar el archivo Excel de historial de estaciones */}
      <Button
        variant="contained"
        color="primary"
        onClick={downloadStationSubscriptionHistory}
        style={{ margin: "20px" }}
      >
        Bajar excel de historial de suscripciones de operadores a estaciones
      </Button>
    </Box>
  );
};

export default Charts;
