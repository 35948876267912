//StationStat component with the following features: Name, Line, and Total Time vs Expected Time, Graphical representation of the station data, and a button to view the station details.
import React from "react";
import config from "./config.js";
import { Button, Typography, Box } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  LabelList,
  Cell,
} from "recharts";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "./styles.css";

const StationStats = ({ productionOrder, currentStationType }) => {
  const [expectedTime, setExpectedTime] = React.useState(null);
  const [currentTime, setCurrentTime] = React.useState(null);
  const [stationType, setStationType] = React.useState(currentStationType);
  const [stationCode, setStationCode] = React.useState(null);
  const [stateProductionOrder, setStateProductionOrder] =
    React.useState(productionOrder);
  const { t } = useTranslation();

  React.useEffect(() => {
    console.log(`Fetching station stats... ${stateProductionOrder.id}`);

    getExpectedTime(productionOrder, stationType);
    fetchCurrentTime(productionOrder, stationType);
  }, [stateProductionOrder]);
  const fetchCurrentTime = async (productionOrder, stationType) => {
    try {
      const accessToken = localStorage.getItem("access_token");

      const response = await axios.get(
        config.url+`/production_orders/${productionOrder.id}/station_type/${stationType["station_type"]}/current_time`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response.status);
      if (response.status === 200) {
        const data = await response.data;
        setCurrentTime(data.total_time);
        setStationCode(data.station_code);
      } else {
        // Handle errors
        console.error("Failed to fetch current time");
      }
    } catch (error) {
      if (error.response.status === 404) {
        console.error(
          "Failed to fetch current time because it does not exist so setting it to 0"
        );
        setCurrentTime(0);
      }
    }
  };

  //Get production order expected time for given station type
  const getExpectedTime = (productionOrder, stationType) => {
    console.log(productionOrder["station_types_order"]);
    const found_station = productionOrder["station_types_order"].find(
      (station) => station[stationType["station_type"]]
    );
    console.log(found_station);
    found_station &&
      setExpectedTime(found_station[stationType["station_type"]]);
  };
  const performanceValue =
    currentTime < expectedTime
      ? 100
      : Math.floor((expectedTime / currentTime) * 100);

  const data = [
    { name: "% " + t("performance"), value: performanceValue },
    { name: "", value: 100 - performanceValue },
  ];

  const COLORS = ["#82ca9d", "#8884d8"];
  //Performance pie chart data if current time is lower than expected time then 100% Performance, else expected time/current time * 100, show the performance percentage and "%" symbol
  return (
    <Box
      sx={{
        mt: 4,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography variant="h6" className="custom-h4">
        {t("station_type")}: {stationType["station_type"]}
      </Typography>
      <Typography variant="h6" className="custom-h4">
        {t("station_unique_code")}: {stationCode}
      </Typography>
      <PieChart width={400} height={400}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={80}
          label={({
            cx,
            cy,
            midAngle,
            innerRadius,
            outerRadius,
            value,
            index,
          }) => {
            const RADIAN = Math.PI / 180;
            const radius = 25 + innerRadius + (outerRadius - innerRadius);
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            return (
              <text
                x={x}
                y={y}
                fill="black"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
              >
                {`${value}%`}
              </text>
            );
          }}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
          <LabelList dataKey="name" position="top" />
        </Pie>
        <Tooltip />
      </PieChart>
      ;
      <br />
      <Typography variant="body1" className="custom-h4">
        {t("expected_time")}: {expectedTime}
      </Typography>
      <Typography variant="body1" className="custom-h4">
        {t("current_time")}: {currentTime}
      </Typography>
    </Box>
  );
};

export default StationStats;
