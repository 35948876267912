//This file contains the StationManagementSidebar component which is the sidebar of the stationmanagement page. It contains the sidebar items for the stationmanagement page.
import React from "react";
import config from "./config.js";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
import {
  Dashboard,
  Settings,
  ExitToApp,
  Info,
  Article,
  ReportProblem,
  Work,
  AddLocationAltSharp,
  Square,
  PeopleAlt,
} from "@mui/icons-material";
import InventoryIcon from "@mui/icons-material/Inventory";
import { handleLogout } from "./Logout";
import { useTranslation } from "react-i18next";

const StationManagementSidebar = ({
  open,
  handleDrawerClose,
  onSectionChange,
}) => {
  const { t } = useTranslation();
  return (
    <Drawer
      onClick={handleDrawerClose}
      sx={{
        width: 240,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 240,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <List>
        {" "}
        {/* List of sidebar items */}
        <ListItem>
          <Typography variant="h6" noWrap>
            {t("stationmanagement_dashboard")}
          </Typography>
        </ListItem>
        <ListItem>
          <Typography variant="h6" noWrap></Typography>
        </ListItem>
        <ListItem button onClick={() => onSectionChange("Production Orders")}>
          <ListItemIcon>
            <Work />
          </ListItemIcon>
          <ListItemText primary={t("production_orders")} />
        </ListItem>
        <ListItem button onClick={() => onSectionChange("Stations")}>
          <ListItemIcon>
            <AddLocationAltSharp />
          </ListItemIcon>
          <ListItemText primary={t("stations")} />
        </ListItem>
        <ListItem button onClick={() => onSectionChange("Station Operators")}>
          <ListItemIcon>
            <PeopleAlt />
          </ListItemIcon>
          <ListItemText primary={t("operators")} />
        </ListItem>
        <ListItem button onClick={() => onSectionChange("Materials")}>
          <ListItemIcon>
            <InventoryIcon />
          </ListItemIcon>
          <ListItemText primary={t("materials")} />
        </ListItem>
        <ListItem button onClick={() => onSectionChange("Floors")}>
          <ListItemIcon>
            <Square />
          </ListItemIcon>
          <ListItemText primary={t("floors")} />
        </ListItem>
        {/*<ListItem button onClick={() => onSectionChange("Tags")}>
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary="Tags" />
        </ListItem>*/}
        <ListItem button onClick={() => onSectionChange("incidents")}>
          <ListItemIcon>
            <ReportProblem />
          </ListItemIcon>
          <ListItemText primary={t("incidents")} />
        </ListItem>
        <ListItem button onClick={() => onSectionChange("documents")}>
          <ListItemIcon>
            <Article />
          </ListItemIcon>
          <ListItemText primary={t("documents")} />
        </ListItem>
        <ListItem button onClick={() => onSectionChange("settings")}>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary={t("settings")} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button onClick={() => onSectionChange("About Us")}>
          <ListItemIcon>
            <Info />
          </ListItemIcon>
          <ListItemText primary={t("about_us")} />
        </ListItem>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary={t("logout")} />
        </ListItem>
        <ListItem>
          <img
            src="FC.png"
            alt="logo"
            style={{ width: "50%", height: "auto", margin: "auto" }}
          />
        </ListItem>
        <ListItem>
          {/*center text*/}
          <Typography variant="h6" noWrap style={{ margin: "auto" }}>
            {"DSA Grupo ®"}
          </Typography>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default StationManagementSidebar;
