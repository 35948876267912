import React, { useState } from "react";
import axios from "axios";
import { Button, Box, TextField } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import config from "./config";

const Charts = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Función para descargar el archivo Excel del historial de suscripciones de órdenes de producción
  const downloadProductionOrderHistory = async () => {
    try {
      if (!startDate || !endDate) {
        alert("Por favor, seleccione un rango de fechas.");
        return;
      }
      const response = await axios.get(
        config.url + "/operator-production-order-subscription-history",
        {
          params: {
            startDate: startDate ? startDate.format("YYYY-MM-DD") : undefined,
            endDate: endDate ? endDate.format("YYYY-MM-DD") : undefined,
          },
          responseType: "blob", // Para manejar la respuesta como un archivo
          validateStatus: (status) => {
            // Permitir que los estados 400 no arrojen un error automáticamente
            return status < 500; // Resuelve normalmente para cualquier estado menor a 500
          },
        }
      );

      // Si el estado es 400, mostrar el mensaje de alerta
      if (response.status === 400) {
        console.log(response.data);
        alert(response.data.message || "Ha ocurrido un error.");
        return; // Salir de la función si es un error
      }

      // Crear un enlace para descargar el archivo si no hubo error
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "operator_production_order_subscription_history.xlsx"
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error(
        "Error downloading production order subscription history",
        error
      );
      alert(
        "Error al descargar el historial de suscripciones de órdenes de producción."
      );
    }
  };

  // Función para descargar el archivo Excel del historial de suscripciones de estaciones
  const downloadStationSubscriptionHistory = async () => {
    try {
      if (!startDate || !endDate) {
        alert("Por favor, seleccione un rango de fechas.");
        return;
      }
      const response = await axios.get(
        config.url + "/operator-station-subscription-history",
        {
          params: {
            startDate: startDate ? startDate.format("YYYY-MM-DD") : undefined,
            endDate: endDate ? endDate.format("YYYY-MM-DD") : undefined,
          },
          responseType: "blob", // Para manejar la respuesta como un archivo
          validateStatus: (status) => {
            // Permitir que los estados 400 no arrojen un error automáticamente
            return status < 500; // Resuelve normalmente para cualquier estado menor a 500
          },
        }
      );

      // Si el estado es 400, mostrar el mensaje de alerta
      if (response.status === 400) {
        console.log(response.data);
        alert(response.data.message || "Ha ocurrido un error.");
        return; // Salir de la función si es un error
      }

      // Crear un enlace para descargar el archivo si no hubo error
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "operator_station_subscription_history.xlsx"
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading station subscription history", error);
      alert("Error al descargar el historial de suscripciones de estaciones.");
    }
  };

  return (
    <Box>
      {/* Selección de rango de fechas */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <DatePicker
            label="Fecha de inicio"
            format="DD/MM/YYYY"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="Fecha de fin"
            format="DD/MM/YYYY"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
      </LocalizationProvider>

      {/* Botón para descargar el archivo Excel de historial de órdenes de producción */}
      <Button
        variant="contained"
        color="secondary"
        onClick={downloadProductionOrderHistory}
        style={{ margin: "20px" }}
      >
        Bajar excel de historial de suscripciones de operadores a órdenes de
        producción
      </Button>

      {/* Botón para descargar el archivo Excel de historial de estaciones */}
      <Button
        variant="contained"
        color="primary"
        onClick={downloadStationSubscriptionHistory}
        style={{ margin: "20px" }}
      >
        Bajar excel de historial de suscripciones de operadores a estaciones
      </Button>
    </Box>
  );
};

export default Charts;
