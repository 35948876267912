import React, { useState, useEffect } from "react";
import config from "./config.js";
import axios from "axios";
import {
  TextField,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
  Select,
  MenuItem,
  Pagination,
} from "@mui/material";
import { Edit, Delete, Topic } from "@mui/icons-material";
import StationStats from "./StationStats";
import { handleLogout } from "./Logout";
import { useTranslation } from "react-i18next";
import "./styles.css";

const options = {
  weekday: "long", // 'narrow', 'short', 'long'
  year: "numeric",
  month: "long", // 'numeric', '2-digit', 'long', 'short', 'narrow'
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  timeZoneName: "short", // To include the GMT information
};

const ProductionOrders = ({ subscribedPOId, setSubscribedPOId }) => {
  const [productionOrders, setProductionOrders] = useState([]);
  const [subProductionOrders, setSubProductionOrders] = useState([]);
  const [selectedProductionOrder, setSelectedProductionOrder] = useState(null);
  const [selectedPOMaterials, setSelectedPOMaterials] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [role, setRole] = useState(null);
  const { t } = useTranslation();
  const [filter, setFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("En proceso y En espera");
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const [carts, setCarts] = useState([]);

  useEffect(() => {
    fetchPOStatuses();
    fetchProductionOrders();
    setRole(localStorage.getItem("role"));
    fetchUserData();
    fetchCarts();
    const interval = setInterval(() => {
      fetchProductionOrders();
    }, 60000); // 300000 milliseconds = 5 minutes

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const fetchCarts = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(config.url + "/carts", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.status === 200) {
        console.log("Cart data:", response.data);
        setCarts(response.data);
      } else {
        console.error("Failed to fetch carts");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const fetchUserData = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(
        config.url + "/user/" + localStorage.getItem("username"),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        console.log("User data:", response.data);
        setSubscribedPOId(response.data.production_order_id);
      } else {
        console.error("Failed to fetch user data");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const filteredProductionOrders = productionOrders.filter(
    (po) =>
      (po.id.toString().includes(filter) ||
        (po.parent_id && po.parent_id.toString().includes(filter)) ||
        (po.type && po.type.toLowerCase().includes(filter.toLowerCase()))) &&
      (statusFilter === "" ||
        po.status === statusFilter ||
        (statusFilter === "En proceso y En espera" &&
          (po.status === "En proceso" || po.status === "En espera")))
  );

  const paginatedProductionOrders = filteredProductionOrders.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  const totalPages = Math.ceil(filteredProductionOrders.length / rowsPerPage);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const fetchPOMaterials = async (productionOrder) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(
        config.url + `/production_orders/${productionOrder.id}/materials`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setSelectedPOMaterials(response.data);
      } else {
        console.error("Failed to fetch materials data");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const fetchProductionOrders = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const url =
        localStorage.getItem("role") === "StationManagement"
          ? config.url + "/production_orders/station-management"
          : config.url + "/all_production_orders";

      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.status === 200) {
        console.log("Production orders:", response.data);

        // Sort the production orders by start_datetime from older to newer
        const sortedOrders = response.data.sort(
          (a, b) => new Date(a.start_datetime) - new Date(b.start_datetime)
        );

        setProductionOrders(sortedOrders);
        fetchSubProductionOrdersForEachParent(sortedOrders);
        console.log("Selected PO:", selectedProductionOrder);
      } else {
        console.error("Failed to fetch production orders");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const fetchSubProductionOrdersForEachParent = async (productionOrders) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const ids = productionOrders.map((po) => po.id);

      const response = await axios.post(
        config.url + `/get_all_sub_nodes_for_each_parent`,
        { ids },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setSubProductionOrders(response.data);
      } else {
        console.error("Failed to fetch sub-production orders");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const fetchSubProductionOrders = async (parent_id) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(
        config.url + `/get_all_sub_nodes/${parent_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setSubProductionOrders((prev) => ({
          ...prev,
          [parent_id]: response.data,
        }));
      } else {
        console.error("Failed to fetch sub-production orders");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const fetchPOStatuses = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(
        config.url + "/production_orders/statuses",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setStatuses(response.data);
      } else {
        console.error("Failed to fetch statuses");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const handleSelection = (productionOrder) => {
    setSelectedProductionOrder(productionOrder);
    fetchPOMaterials(productionOrder);
    fetchSubProductionOrders(productionOrder.id);
  };

  const handlePOSubscription = async (productionOrder) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.post(
        config.url + `/production_orders/${productionOrder}/subscribe`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setSubscribedPOId(productionOrder);

        // Cambiar el estado a "En proceso" automáticamente
        const inProgressStatus = statuses.find(
          (status) => status.name === "En proceso"
        );
        if (inProgressStatus) {
          await updateStatus({ id: productionOrder }, inProgressStatus);
        }

        updateProductionOrderState(productionOrder, true);
      } else {
        console.error("Failed to subscribe");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const handlePOUnsubscription = async (productionOrder) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.post(
        config.url + `/production_orders/${productionOrder}/unsubscribe`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setSubscribedPOId(null);
        updateProductionOrderState(productionOrder, false);
      } else {
        console.error("Failed to unsubscribe");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const updateProductionOrderState = (productionOrderId, isSubscribed) => {
    const currentUsername = localStorage.getItem("username");

    setProductionOrders((prevOrders) =>
      prevOrders.map((po) =>
        po.id === productionOrderId
          ? {
              ...po,
              usernames: isSubscribed
                ? [...po.usernames, currentUsername]
                : po.usernames.filter(
                    (username) => username !== currentUsername
                  ),
            }
          : po
      )
    );

    if (selectedProductionOrder?.id === productionOrderId) {
      setSelectedProductionOrder((prevOrder) =>
        prevOrder
          ? {
              ...prevOrder,
              usernames: isSubscribed
                ? [...prevOrder.usernames, currentUsername]
                : prevOrder.usernames.filter(
                    (username) => username !== currentUsername
                  ),
            }
          : null
      );
    }
  };

  const handleCheckBoxChange = (productionOrderId) => async (event) => {
    event.stopPropagation();

    try {
      if (subscribedPOId === productionOrderId) {
        await handlePOUnsubscription(productionOrderId);
      } else {
        if (subscribedPOId) {
          await handlePOUnsubscription(subscribedPOId);
        }
        await handlePOSubscription(productionOrderId);
      }
    } catch (error) {
      console.error(
        "An error occurred while updating subscription status:",
        error
      );
    }
    fetchProductionOrders();
  };

  const handleStatusChange = (event) => {
    const selectedStatus = statuses.find(
      (status) => status.name === event.target.value
    );
    if (
      selectedProductionOrder?.usernames.includes(
        localStorage.getItem("username")
      )
    ) {
      updateStatus(selectedProductionOrder, selectedStatus);
    } else {
      console.warn(
        "You must be subscribed to this production order to change its status."
      );
    }
  };

  const handleCartChange = async (event) => {
    console.log("Carts:", carts);
    console.log(selectedProductionOrder.cart);

    const selectedCart = carts.find(
      (cart) => cart.reference_code === event.target.value
    );
    console.log(event.target.value);
    console.log("Selected cart:", selectedCart);

    if (
      selectedProductionOrder?.usernames.includes(
        localStorage.getItem("username")
      ) ||
      role === "Admin" ||
      role === "StationManagement"
    ) {
      if (selectedCart) {
        linkCart(selectedProductionOrder, selectedCart);
      } else {
        unlinkCart(selectedProductionOrder, selectedProductionOrder.cart);
      }
    } else {
      console.warn(
        "You must be subscribed to this production order to change its cart."
      );
    }
  };
  const linkCart = async (productionOrder, cart) => {
    const reference_code = cart ? cart.reference_code : null;
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.put(
        config.url + `/production_orders/${productionOrder.id}/cart`,
        { reference_code: reference_code },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setSelectedProductionOrder((prevOrder) =>
          prevOrder ? { ...prevOrder, cart: reference_code } : null
        );

        fetchProductionOrders();

        console.log("Selected PO:", selectedProductionOrder);
      } else {
        console.error("Failed to update cart");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };
  const unlinkCart = async (productionOrder, cart) => {
    const reference_code = cart;
    try {
      const accessToken = localStorage.getItem("access_token");
      console.log("Unlinking cart:", reference_code);
      const response = await axios.put(
        config.url + `/production_orders/${productionOrder.id}/uncart`,
        { reference_code: reference_code },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setSelectedProductionOrder((prevOrder) =>
          prevOrder ? { ...prevOrder, cart: null } : null
        );

        fetchProductionOrders();

        console.log("Selected PO:", selectedProductionOrder);
      } else {
        console.error("Failed to update cart");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const updateStatus = async (productionOrder, status) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.put(
        config.url + `/production_orders/${productionOrder.id}/status`,
        { status: status.id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setSelectedProductionOrder((prevOrder) =>
          prevOrder
            ? { ...prevOrder, status: status.name, status_color: status.color }
            : null
        );

        // Si el estado es "Finalizada", desuscribir al usuario
        if (status.name === "Finalizada") {
          await handlePOUnsubscription(productionOrder.id);
        }

        fetchProductionOrders();
      } else {
        console.error("Failed to update status");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const handlePOPicking = async (productionOrderId) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.post(
        config.url + `/production_orders/${productionOrderId}/trigger_picking`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        console.log("Picking triggered successfully");
      } else {
        console.error("Failed to trigger picking");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const handleErrorResponse = (error) => {
    if (error.response && error.response.status > 400) {
      console.log("Unauthorized");
      handleLogout();
    }
    console.error("Error:", error);
  };

  return (
    <Box>
      <Typography variant="h4" className="custom-h4">
        {selectedProductionOrder
          ? `${t("production_order")}: ${selectedProductionOrder.id}`
          : t("production_orders")}
      </Typography>
      {selectedProductionOrder && (
        <>
          <Typography variant="h5">{t("status")}:</Typography>
          <Box
            style={{
              backgroundColor: selectedProductionOrder.status_color,
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Select
              style={{ width: "100%" }}
              value={selectedProductionOrder.status}
              onChange={handleStatusChange}
            >
              {statuses.map((status) => (
                <MenuItem key={status.id} value={status.name}>
                  {status.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Typography variant="h5">{t("cart")}:</Typography>
          <Box
            style={{
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Select
              style={{ width: "100%" }}
              value={selectedProductionOrder.cart}
              onChange={handleCartChange}
            >
              <MenuItem key={null} value={null}>
                {t("none")}
              </MenuItem>
              {carts.map((cart) => (
                <MenuItem key={cart.reference_code} value={cart.reference_code}>
                  {cart.reference_code}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </>
      )}
      {selectedProductionOrder && role === "Operator" && (
        <Box>
          <Typography variant="h5">{t("subscribed")}:</Typography>
          <IconButton onClick={(event) => event.stopPropagation()}>
            <Checkbox
              checked={subscribedPOId === selectedProductionOrder.id}
              onChange={handleCheckBoxChange(selectedProductionOrder.id)}
              inputProps={{ "aria-label": "controlled" }}
              onClick={(event) => event.stopPropagation()}
            />
          </IconButton>
        </Box>
      )}

      {!selectedProductionOrder && (
        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            label={t("filter_by_bathroom_id")}
            variant="outlined"
            value={filter}
            onChange={handleFilterChange}
            sx={{ mb: 2, width: "100%", maxWidth: "500px" }}
          />
          <Select
            label={t("filter_by_status")}
            value={statusFilter}
            onChange={(event) => setStatusFilter(event.target.value)}
            sx={{ mb: 2, width: "100%", maxWidth: "500px" }}
          >
            <MenuItem key="-1" value="">
              {t("all_statuses")}
            </MenuItem>
            <MenuItem key="0" value="En proceso y En espera">
              {t("En proceso y En espera")}
            </MenuItem>
            {statuses.map((status) => (
              <MenuItem key={status.id} value={status.name}>
                {status.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}

      {selectedProductionOrder && (
        <>
          <Typography variant="h4" className="custom-h4">
            {t("materials")}
          </Typography>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("material_id")}</TableCell>
                  <TableCell>{t("description")}</TableCell>
                  <TableCell>{t("reference")}</TableCell>
                  <TableCell>{t("quantity")}</TableCell>
                  <TableCell>{t("unit_of_measurement")}</TableCell>
                  <TableCell>{t("material_lot_number")}</TableCell>
                  <TableCell>{t("carbon_footprint_coefficient")}</TableCell>
                  <TableCell>{t("station_type")}</TableCell>
                  <TableCell>{t("status")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedPOMaterials.map((material) => (
                  <TableRow key={material.id}>
                    <TableCell>{material.id}</TableCell>
                    <TableCell>{material.description}</TableCell>
                    <TableCell>{material.reference}</TableCell>
                    <TableCell>{material.quantity}</TableCell>
                    <TableCell>{material.unit_of_measurement}</TableCell>
                    <TableCell>{material.lot_number}</TableCell>
                    <TableCell>
                      {material.carbon_footprint_coefficient}
                    </TableCell>
                    <TableCell>{material.station_type}</TableCell>
                    <TableCell
                      style={{ backgroundColor: material.status_color }}
                    >
                      {material.status}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {filteredProductionOrders.length > 0 && (
        <>
          <Box sx={{ mt: 4 }} />
          {!selectedProductionOrder && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("order_id")}</TableCell>
                    <TableCell>{t("parent_id")}</TableCell>
                    <TableCell>{t("expiration_date")}</TableCell>
                    <TableCell>{t("description")}</TableCell>
                    <TableCell>{t("start_datetime")}</TableCell>
                    <TableCell>{t("end_datetime")}</TableCell>
                    <TableCell>{t("type")}</TableCell>
                    <TableCell>{t("planned_line")}</TableCell>
                    <TableCell>{t("current_station")}</TableCell>
                    {(role === "Operator" || role === "StationManagement") && (
                      <TableCell>{t("subscribed")}</TableCell>
                    )}
                    <TableCell>{t("trigger_picking")}</TableCell>
                    <TableCell>{t("incidents")}</TableCell>
                    <TableCell>{t("status")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedProductionOrders.map((po) => (
                    <React.Fragment key={po.id}>
                      <TableRow onClick={() => handleSelection(po)}>
                        <TableCell>{po.id}</TableCell>
                        <TableCell>{po.parent_id}</TableCell>
                        <TableCell>
                          {new Intl.DateTimeFormat(
                            config.langTZ,
                            options
                          ).format(new Date(po.expiration_date))}
                        </TableCell>

                        <TableCell>{po.description}</TableCell>
                        <TableCell>
                          {new Intl.DateTimeFormat(
                            config.langTZ,
                            options
                          ).format(new Date(po.start_datetime))}
                        </TableCell>
                        <TableCell>
                          {new Intl.DateTimeFormat(
                            config.langTZ,
                            options
                          ).format(new Date(po.end_datetime))}
                        </TableCell>
                        <TableCell>{po.type}</TableCell>
                        <TableCell>{po.planned_line}</TableCell>
                        <TableCell>{po.current_station}</TableCell>
                        {(role === "Operator" ||
                          role === "StationManagement") && (
                          <TableCell>
                            <IconButton
                              onClick={(event) => event.stopPropagation()}
                            >
                              <Checkbox
                                checked={subscribedPOId === po.id}
                                onChange={handleCheckBoxChange(po.id)}
                                inputProps={{ "aria-label": "controlled" }}
                                onClick={(event) => event.stopPropagation()}
                              />
                            </IconButton>
                          </TableCell>
                        )}
                        <TableCell>
                          {po.parent_id === null ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={(event) => {
                                event.stopPropagation();
                                handlePOPicking(po.id);
                              }}
                            >
                              {t("start")}
                            </Button>
                          ) : (
                            t("use_button_from_parent")
                          )}
                        </TableCell>
                        <TableCell>
                          {po.incidents &&
                            Object.entries(po.incidents)
                              .filter(([key, value]) => value.includes("Open"))
                              .map(([key, value]) => (
                                <div key={key}>
                                  {key}: {value}
                                </div>
                              ))}
                        </TableCell>

                        <TableCell
                          style={{
                            backgroundColor: po.status_color,
                          }}
                        >
                          {po.status}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              color="primary"
            />
            <Typography sx={{ ml: 2 }}>
              {t("page")} {page} {t("of")} {totalPages}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ProductionOrders;
