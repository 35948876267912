import React, { useState, useEffect, useRef } from "react";
import config from "./config.js";
import axios from "axios";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { handleLogout } from "./Logout";
import TextField from "./TextField";
import { useTranslation } from "react-i18next";
import useComponentVisibility from "../hooks/useComponentVisibility";
import "./styles.css";

const Materials = () => {
  const { t } = useTranslation();
  const [materials, setMaterials] = useState([]);
  const [materialRequests, setMaterialRequests] = useState([]);
  const [modulaId, setModulaId] = useState("");
  const [open, setOpen] = useState(false);
  const [modulaStatus, setModulaStatus] = useState(t("not_requested"));
  const [modulaStatusColor, setModulaStatusColor] = useState("green");

  const [currentStationId, setCurrentStationId] = useState("");
  const [currentProductionOrderId, setCurrentProductionOrderId] = useState("");
  const [currentMaterialRequest, setCurrentMaterialRequest] = useState({
    id: null,
    reference: null,
    unit_of_measurement: "",
    quantity: "",
    production_order_id: "",
  });
  const [currentMaterial, setCurrentMaterial] = useState({
    id: null,
    description: "",
    quantity: "",
    lot_number: "",
    source: "",
    status: "",
    status_color: "",
  });
  const [isVisible, ref] = useComponentVisibility();
  const pollingRef = useRef({}); // To keep track of intervals for each command

  useEffect(() => {
    console.log("Fetching materials...");
    fetchMaterials();
    fetchModulaId();
    fetchCurrentUserElementIds();
    console.log(currentProductionOrderId);
    // Cleanup intervals on component unmount
    return () => {
      console.log("Clearing intervals on unmount");
      Object.values(pollingRef.current).forEach(clearInterval);
    };
  }, []);

  const fetchCurrentUserElementIds = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(
        config.url + `/user/${localStorage.getItem("username")}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        const data = await response.data;
        console.log(data);
        setCurrentStationId(data.station_id);
        setCurrentProductionOrderId(data.production_order_id);
        fetchMaterialRequests(data.production_order_id);
      } else {
        console.error("Failed to fetch user data");
      }
    } catch (error) {
      if (error.response.status > 400) {
        // Unauthorized
        console.log("Unauthorized");
        handleLogout();
      }
      console.error("Error fetching user:", error);
    }
  };

  const fetchModulaId = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");

      const response = await axios.get(config.url + `/modula_id`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response);
      if (response.status === 200) {
        const data = await response.data;
        console.log(data);
        setModulaId(data.modula_id);
        pollStatus(data.modula_id);
      } else {
        console.error("Failed to fetch modula id");
      }
    } catch (error) {
      if (error.response?.status > 400) {
        console.log("Unauthorized");
        handleLogout();
      }
      console.error("Error fetching modula id:", error);
    }
  };
  const pollStatus = (modulaId) => {
    console.log("Polling status for modula:", modulaId);

    // Function to check the status
    const checkStatus = async () => {
      if (!isVisible) {
        clearInterval(pollingRef.current[modulaId]);
        return;
      }

      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await axios.get(
          config.url + `/modula/check_status/${modulaId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.data.status === "Completed") {
          clearInterval(pollingRef.current[modulaId]);
          setModulaStatus("Completed");
          setModulaStatusColor("green");
        } else {
          setModulaStatus(response.data.status);
          setModulaStatusColor(response.data.status_color);
        }

        console.log(response);
      } catch (error) {
        console.error("Error checking status", error);
        clearInterval(pollingRef.current[modulaId]);
      }
    };

    // Initial call to check status immediately
    checkStatus();

    // Clear any existing polling for the modulaId
    if (pollingRef.current[modulaId]) {
      clearInterval(pollingRef.current[modulaId]);
    }

    // Start new polling interval
    pollingRef.current[modulaId] = setInterval(checkStatus, 5000);
  };

  const handleRequestMaterialtoModula = async (modulaId) => {
    try {
      const accessToken = localStorage.getItem("access_token");

      const response = await axios.put(
        config.url + `/modula/${modulaId}/`,
        {},
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        const data = await response.data;
        console.log(data); // Use the data as needed
        pollStatus(modulaId);
      } else {
        console.error("Failed to request material to modula");
      }
    } catch (error) {
      if (error.response?.status > 400) {
        console.log("Unauthorized");
        handleLogout();
      }
      console.error("Error requesting material to modula:", error);
    }
  };

  const fetchMaterials = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");

      const response = await axios.get(config.url + `/materials`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response);
      if (response.status === 200) {
        const data = await response.data;
        console.log(data);
        const ordered_data = data.sort((a, b) => a.id - b.id);
        console.log(ordered_data);
        setMaterials(ordered_data);
      } else {
        console.error("Failed to fetch materials");
      }
    } catch (error) {
      if (error.response?.status > 400) {
        console.log("Unauthorized");
        handleLogout();
      }
      console.error("Error fetching materials:", error);
    }
  };

  const handleRequestMaterial = (material) => {
    console.log("Requesting material:", material);
    try {
      const accessToken = localStorage.getItem("access_token");

      const response = axios.put(
        config.url + `/materials/${material.production_order_id}/request`,
        {},
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        const data = response.data;
        console.log(data); // Use the data as needed
        fetchMaterials();
      } else {
        console.error("Failed to request material");
      }
    } catch (error) {
      if (error.response?.status > 400) {
        console.log("Unauthorized");
        handleLogout();
      }
      console.error("Error requesting material:", error);
    }
  };

  const fetchMaterialRequests = async (currentProductionOrderId) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      console.log(currentProductionOrderId);

      const response = await axios.get(
        config.url + `/material-request/${currentProductionOrderId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        const data = await response.data;
        console.log(data);
        const ordered_data = data.sort((a, b) => a.id - b.id);
        console.log(ordered_data);
        setMaterialRequests(ordered_data);
      } else {
        console.error("Failed to fetch material requests");
      }
    } catch (error) {
      if (error.response?.status > 400) {
        console.log("Unauthorized");
        handleLogout();
      }
      console.error("Error fetching material requests:", error);
    }
  };

  const handleReject = async (material) => {
    try {
      const accessToken = localStorage.getItem("access_token");

      const response = await axios.put(
        config.url + `/materials/${material.id}/reject`,
        {},
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        const data = await response.data;
        console.log(data); // Use the data as needed
        fetchMaterials();
      } else {
        console.error("Failed to delete material");
      }
    } catch (error) {
      if (error.response?.status > 400) {
        console.log("Unauthorized");
        handleLogout();
      }
      console.error("Error deleting material:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setCurrentMaterialRequest({ ...currentMaterialRequest, [name]: value });
  };

  const handleSave = async (id) => {
    console.log(currentMaterialRequest);
    const accessToken = localStorage.getItem("access_token");
    currentMaterialRequest.station_id = currentStationId;
    currentMaterialRequest.material_id = id;
    console.log(currentMaterialRequest);

    await axios.post(
      config.url +
        `/material-request/${currentMaterialRequest.production_order_id}`,
      currentMaterialRequest,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    fetchMaterialRequests(currentProductionOrderId);
    handleClose();
  };

  const handleOpen = (material) => {
    const materialRequest = {
      id: material.id,
      reference: material.reference,
      unit_of_measurement: material.unit_of_measurement,
      quantity: material.quantity,
      production_order_id: material.production_order_id,
    };
    console.log(materialRequest);
    setCurrentMaterialRequest(materialRequest);
    setOpen(true);
  };

  useEffect(() => {
    if (!isVisible) {
      console.log("Component not visible, clearing intervals.");
      Object.values(pollingRef.current).forEach(clearInterval);
      pollingRef.current = {}; // Reset polling reference
    }
  }, [isVisible]);

  return (
    <div ref={ref}>
      <Box>
        <Typography variant="h4" className="custom-h4">
          {t("materials")}
        </Typography>
        {modulaId !== "" ? (
          <>
            <Typography variant="h4" className="custom-h4">
              {t("modula_request_management")}:{" "}
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("modula_id")}</TableCell>
                    <TableCell>{t("status")}</TableCell>
                    <TableCell>{t("action")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{modulaId}</TableCell>
                    <TableCell style={{ backgroundColor: modulaStatusColor }}>
                      {modulaStatus}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => handleRequestMaterialtoModula(modulaId)}
                      >
                        <ArrowCircleDownIcon
                          message={t("request_material_to_modula")}
                        />
                        <Typography>
                          {t("request_material_to_modula")}
                        </Typography>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Typography variant="h4" className="custom-h4">
            {t("there_is_no_modula_request_associated_with_this_po")}
          </Typography>
        )}
        <Typography variant="h4" className="custom-h4">
          {t("po_materials") + ": "}
          {materials.length > 0 && `${materials[0].production_order_id}`}
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("reference")}</TableCell>
                <TableCell>{t("material_description")}</TableCell>
                <TableCell>{t("station_type")}</TableCell>
                <TableCell>{t("unit_of_measurement")}</TableCell>
                <TableCell>{t("material_quantity")}</TableCell>
                <TableCell>{t("material_lot_number")}</TableCell>
                <TableCell>{t("material_source")}</TableCell>
                <TableCell>{t("status")}</TableCell>
                <TableCell>{t("actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {materials.map((material) => (
                <TableRow key={material.id}>
                  <TableCell>{material.reference}</TableCell>
                  <TableCell>{material.description}</TableCell>
                  <TableCell>{material.station_type}</TableCell>
                  <TableCell>{material.unit_of_measurement}</TableCell>
                  <TableCell>{material.quantity}</TableCell>
                  <TableCell>{material.lot_number}</TableCell>
                  <TableCell>{material.source}</TableCell>
                  <TableCell style={{ backgroundColor: material.status_color }}>
                    {material.status}
                  </TableCell>
                  <TableCell>
                    {material.status_color !== "red" ? (
                      <IconButton
                        sx={{ color: "red" }}
                        onClick={() => handleReject(material)}
                      >
                        <Delete />
                        <Typography>{t("reject_material")}</Typography>
                      </IconButton>
                    ) : (
                      <IconButton
                        color="primary"
                        onClick={() => handleOpen(material)}
                      >
                        <ArrowCircleDownIcon
                          message={t("request_material_to_erp")}
                        />
                        <Typography>{t("request_material_to_erp")}</Typography>
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="h4" className="custom-h4">
          {t("material_requests") + ": "}
          {materials.length > 0 && `${materials[0].production_order_id}`}
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("reference")}</TableCell>
                <TableCell>{t("material_description")}</TableCell>
                <TableCell>{t("user")}</TableCell>
                <TableCell>{t("station_type")}</TableCell>
                <TableCell>{t("station_code")}</TableCell>
                <TableCell>{t("unit_of_measurement")}</TableCell>
                <TableCell>{t("material_quantity")}</TableCell>
                <TableCell>{t("material_lot_number")}</TableCell>
                <TableCell>{t("material_source")}</TableCell>
                <TableCell>{t("status")}</TableCell>
                <TableCell>{t("actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {materialRequests.map((material) => (
                <TableRow key={material.id}>
                  <TableCell>{material.reference}</TableCell>
                  <TableCell>{material.description}</TableCell>
                  <TableCell>{material.user}</TableCell>
                  <TableCell>{material.station_type}</TableCell>
                  <TableCell>{material.station_code}</TableCell>
                  <TableCell>{material.unit_of_measurement}</TableCell>
                  <TableCell>{material.quantity}</TableCell>
                  <TableCell>{material.lot_number}</TableCell>
                  <TableCell>{material.source}</TableCell>
                  <TableCell style={{ backgroundColor: material.status_color }}>
                    {material.status}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      sx={{ color: "red" }}
                      onClick={() => handleReject(material)}
                    >
                      <Delete />
                      <Typography>{t("reject_material")}</Typography>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <DialogTitle>{t("material_request")}</DialogTitle>
            <DialogContentText>
              {t("fill_the_following_fields_to_request_material")}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              name="reference"
              label={t("reference")}
              type="text"
              fullWidth
              value={currentMaterialRequest.reference}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="unit_of_measurement"
              label={t("unit_of_measurement")}
              type="text"
              fullWidth
              readOnly
              value={currentMaterialRequest.unit_of_measurement}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="quantity"
              label={t("material_quantity")}
              type="number"
              fullWidth
              value={currentMaterialRequest.quantity}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="production_order_id"
              label={t("production_order_id")}
              type="text"
              fullWidth
              readOnly
              value={currentMaterialRequest.production_order_id}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {t("cancel")}
            </Button>
            <Button
              onClick={() => handleSave(currentMaterialRequest.id)}
              color="primary"
            >
              {t("request")}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
};

export default Materials;
