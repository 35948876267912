import React, { useState, useEffect } from "react";
import config from "./config.js";

import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { Edit, Delete, Warehouse } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import "./styles.css";
import axios from "axios";

const options = {
  weekday: "long", // 'narrow', 'short', 'long'
  year: "numeric",
  month: "long", // 'numeric', '2-digit', 'long', 'short', 'narrow'
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  timeZoneName: "short", // To include the GMT information
};

/*Manage storehouse kit data*/

const VerticalWarehouse = () => {
  const [kits, setKits] = useState([]);
  const [open, setOpen] = useState(false);
  const [stations, setStations] = useState([]); // Add station state

  const [currentKit, setCurrentKit] = useState({
    id: null,
    station_id: "",
    description: "",
    date: "",
    status: "",
    production_order_id: "",
    user_id: "",
  });
  const { t } = useTranslation();

  useEffect(() => {
    fetchKits();
    fetchStations();
  }, []);

  const fetchKits = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(config.url + "/kits", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.status === 200) {
        setKits(response.data);
        console.log(response.data);
      } else {
        console.error("Failed to fetch kits");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const fetchStations = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(config.url + "/stations", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.status === 200) {
        setStations(response.data);
      } else {
        console.error("Failed to fetch stations");
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const handleErrorResponse = (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        handleLogout();
      } else {
        console.error(error.response.data);
      }
    } else {
      console.error(error);
    }
  };

  const handleOpen = (kit) => {
    setCurrentKit(kit);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (id) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.delete(config.url + "/kits/" + id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.status === 200) {
        fetchKits();
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    window.location.reload();
  };

  const handleEdit = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.put(
        config.url + "/kits/" + currentKit.id,
        currentKit,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        fetchKits();
        setOpen(false);
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  return (
    <Box>
      <Typography variant="h4" className="custom-h4">
        {t("vertical_warehouse")}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("ID")}</TableCell>
              <TableCell>{t("date")}</TableCell>
              <TableCell>{t("status")}</TableCell>
              <TableCell>{t("actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {kits.map((kit) => (
              <TableRow key={kit.id}>
                <TableCell>{kit.id}</TableCell>
                <TableCell>
                  {" "}
                  {new Intl.DateTimeFormat(config.langTZ, options).format(
                    new Date(kit.date)
                  )}
                </TableCell>
                <TableCell>{kit.status}</TableCell>

                <TableCell>
                  <IconButton onClick={() => handleDelete(kit.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("Edit Kit")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("Edit the kit")}</DialogContentText>
          <TextField
            label={t("Station")}
            value={currentKit.station_id}
            onChange={(e) =>
              setCurrentKit({ ...currentKit, station_id: e.target.value })
            }
          />
          <TextField
            label={t("Description")}
            value={currentKit.description}
            onChange={(e) =>
              setCurrentKit({ ...currentKit, description: e.target.value })
            }
          />
          <TextField
            label={t("Date")}
            value={currentKit.date}
            onChange={(e) =>
              setCurrentKit({ ...currentKit, date: e.target.value })
            }
          />
          <TextField
            label={t("Status")}
            value={currentKit.status}
            onChange={(e) =>
              setCurrentKit({ ...currentKit, status: e.target.value })
            }
          />
          <TextField
            label={t("Production Order ID")}
            value={currentKit.production_order_id}
            onChange={(e) =>
              setCurrentKit({
                ...currentKit,
                production_order_id: e.target.value,
              })
            }
          />
          <TextField
            label={t("User ID")}
            value={currentKit.user_id}
            onChange={(e) =>
              setCurrentKit({ ...currentKit, user_id: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("Cancel")}</Button>
          <Button onClick={handleEdit}>{t("Save")}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default VerticalWarehouse;
