/*Manager for the incidents page with DialogBOX. It contains the content items for the incidents page.

CREATE TABLE public.station_incidence (
	id serial4 NOT NULL,
	station_id int4 NULL,
	description text NULL,
	"date" timestamp NULL,
	status varchar NULL,
	production_order_id varchar NULL,
	user_id int4 NULL,
	CONSTRAINT station_incidence_pkey PRIMARY KEY (id)
);


-- public.station_incidence foreign keys

ALTER TABLE public.station_incidence ADD CONSTRAINT station_incidence_station_id_fkey FOREIGN KEY (station_id) REFERENCES public.station(id);
ALTER TABLE public.station_incidence ADD CONSTRAINT station_incidence_user_id_fkey FOREIGN KEY (user_id) REFERENCES public."user"(id);*/

import React, { useState, useEffect } from "react";
import config from "./config.js";

import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import "./styles.css";
import axios from "axios";

/* The same as AntennaManager.js, but for Incidents */
const options = {
  weekday: "long", // 'narrow', 'short', 'long'
  year: "numeric",
  month: "long", // 'numeric', '2-digit', 'long', 'short', 'narrow'
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  timeZone: config.timeZone,
  timeZoneName: "short", // To include the GMT information
};
const Incidents = ({ subscribedPOId }) => {
  const [incidents, setIncidents] = useState([]);
  const [open, setOpen] = useState(false);
  const [stations, setStations] = useState([]); // Add station state

  const [currentIncident, setCurrentIncident] = useState({
    id: null,
    station_id: "",
    description: "",
    date: "",
    status: "",
    production_order_id: "",
    user_id: "",
  });
  const { t } = useTranslation();

  useEffect(() => {
    console.log(subscribedPOId);
    console.log("Fetching incidents...");

    fetchIncidents();
    fetchStations();
  }, []);

  const fetchStations = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(config.url + `/stations`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response);
      setStations(response.data);
    } catch (error) {
      if (error.response.status > 400) {
        // Unauthorized
        console.log("Unauthorized");
      }
      console.error("Error fetching stations:", error);
    }
  };

  const fetchIncidents = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(config.url + "/incidents", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response);
      setIncidents(response.data);
    } catch (error) {
      console.error("Error fetching incidents:", error);
    }
  };

  const handleEdit = (incident) => {
    setCurrentIncident(incident);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      await fetch(config.url + `/incidents/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      fetchIncidents();
    } catch (error) {
      console.error("Error deleting incident:", error);
    }
  };

  const handleSave = async () => {
    try {
      console.log("incident: ", currentIncident);
      const accessToken = localStorage.getItem("access_token");
      const url =
        config.url +
        `/incidents${currentIncident.id ? `/${currentIncident.id}` : ""}`;
      const method = currentIncident.id ? "PUT" : "POST";

      const response = await axios({
        method: method,
        url: url,
        data: currentIncident,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      console.log(response);
      fetchIncidents();
      setOpen(false);
    } catch (error) {
      console.error("Error saving incident:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setCurrentIncident({ ...currentIncident, [name]: value });
    console.log("incident: ", currentIncident);
  };

  return (
    <Box>
      <Typography variant="h4" className="custom-h4">
        {t("incidents")}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setCurrentIncident({
            production_order_id: subscribedPOId,
            station_id: localStorage.getItem("station_id"),
          });
          setOpen(true);
        }}
      >
        {t("add_incident")}
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("station")}</TableCell>
              <TableCell>{t("description")}</TableCell>
              <TableCell>{t("date")}</TableCell>
              <TableCell>{t("status")}</TableCell>
              <TableCell>{t("production_order_id")}</TableCell>
              <TableCell>{t("user")}</TableCell>
              <TableCell>{t("actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {incidents.map((incident) => (
              <TableRow key={incident.id}>
                <TableCell>{incident.station}</TableCell>
                <TableCell>{incident.description}</TableCell>
                <TableCell>
                  {new Intl.DateTimeFormat(config.langTZ, options).format(
                    new Date(incident.date)
                  )}
                </TableCell>
                <TableCell>{t(incident.status.toLowerCase())}</TableCell>
                <TableCell>{incident.production_order_id}</TableCell>
                <TableCell>{incident.user}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(incident)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(incident.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {currentIncident.id ? t("edit_incident") : t("add_incident")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormControl fullWidth margin="dense">
              <InputLabel id="station-select-label">{t("station")}</InputLabel>
              <Select
                name="station_id"
                labelId="station-select-label"
                value={
                  currentIncident.id
                    ? currentIncident.station_id
                    : localStorage.getItem("station_id")
                }
                onChange={handleChange}
              >
                {stations.map((station) => (
                  <MenuItem key={station.id} value={station.id}>
                    {station.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="dense">
              <TextField
                name="description"
                label={t("description")}
                value={currentIncident.description}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl fullWidth margin="dense">
              <InputLabel id="status-select-label">{t("status")}</InputLabel>
              <Select
                name="status"
                labelId="status-select-label"
                value={currentIncident.status}
                onChange={handleChange}
              >
                <MenuItem key="1" value="Open">
                  {t("open")}
                </MenuItem>
                <MenuItem key="2" value="Closed">
                  {t("closed")}
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="dense">
              <TextField
                name="production_order_id"
                label={t("production_order_id")}
                value={
                  currentIncident.id
                    ? currentIncident.production_order_id
                    : subscribedPOId
                }
                onChange={handleChange}
              />
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>{t("save")}</Button>
          <Button onClick={handleClose}>{t("cancel")}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Incidents;
